<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4">
      <h4 class="font-weight-bold text-dark">
        <i class="fas fa-exclamation-circle text-danger fa-md"></i>
        <span class="ml-2" style="vertical-align: center">Alert</span>
      </h4>
      <div>
        <textarea
          type="text"
          :class="{ 'full-height': showAll || isEditMode, 'collapsed-height': !showAll && !isEditMode }"
          class="form-control col-12 pt-5 pb-5"
          maxlength="32000"
          v-model="appData.asset.K_Alert"
          :readonly="isReadOnly || !isEditMode"
          ref="textAreaRef"
        />
        <small v-if="isEditMode" class="form-text text-muted">
          Max 32,000 characters.
        </small>
      </div>

      <div class="form-group mt-4 mb-0">
        <button
          v-if="!isEditMode"
          class="btn btn-primary btn-sm"
          @click.prevent="editAlert"
          :disabled="isReadOnly"
        >
          <i class="fas fa-edit"></i>
          Edit
        </button>

        <button
          v-if="!isEditMode"
          class="btn btn-outline-secondary btn-sm ml-3"
          @click="toggleShowAll"
        >
          {{ showAll ? 'Show Less' : 'Show All' }}
        </button>

        <template v-if="isEditMode">
          <button
            class="btn btn-primary btn-sm"
            @click.prevent="saveAlert"
            :disabled="savingData"
          >
            Save
          </button>
          <b-spinner
            class="ml-3 align-middle"
            variant="primary"
            v-if="savingData"
          ></b-spinner>
          <button
            class="btn btn-secondary btn-sm ml-3"
            @click.prevent="cancelEditMode"
            :disabled="savingData"
          >
            Cancel
          </button>
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "PolicyAlert",
  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      tempAlertText: this.appData.asset.K_Alert,
      isEditMode: false,
      savingData: false,
      showAll: false,
    };
  },

  mounted() {
    this.adjustTextAreaHeight();
  },

  watch: {
    isEditMode() {
      this.adjustTextAreaHeight();
    },
    showAll() {
      this.adjustTextAreaHeight();
    },
    'appData.asset.K_Alert'() {
      this.adjustTextAreaHeight();
    },
    isReadOnly(newVal) {
      if (newVal) {
        this.isEditMode = false;
      }
      this.adjustTextAreaHeight();
    }
  },

  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    editAlert() {
      if (!this.isReadOnly) {
        this.isEditMode = true;
        this.tempAlertText = this.appData.asset.K_Alert;
      }
    },

    adjustTextAreaHeight() {
      this.$nextTick(() => {
        const textArea = this.$refs.textAreaRef;
        if (textArea) {
          if (this.showAll || this.isEditMode) {
            textArea.style.height = 'auto';
            textArea.style.height = textArea.scrollHeight + 'px';
          } else {
            textArea.style.height = '100px';
          }
        }
      });
    },

    cancelEditMode() {
      this.appData.asset.K_Alert = this.tempAlertText;
      this.isEditMode = false;
    },

    saveAlert() {
      this.savingData = true;
      this.$commonDataService
        .savePolicyAlert(this.appData.asset.K_Alert)
        .then(() => {
          this.isEditMode = false;
          this.savingData = false;
        })
        .catch((error) => {
          this.savingData = false;
          this.isEditMode = false;
          this.$emit("handleError", error);
        });
    },
  },
};
</script>
