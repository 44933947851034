<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'policy-details-panel'"
      >
        <i class="fas fa-list-alt fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Policy Details</span>

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse id="policy-details-panel" v-model="isExpanded">
        <div v-if="appData && refData">
          <!-- ROW 1 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right">Policy Name</label>
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="Name"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="255"
                  v-model="appData.policy.Name"
                  :disabled="isReadOnly"
                  @blur="$emit('updateWizardTitle')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Policy Owner<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="Owner"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.policy.Owner"
                  :disabled="isReadOnly"
                >
                  <option
                    v-for="user in refData.activeUsers"
                    :key="user.id"
                    :value="user"
                  >
                    {{ user.name }}
                  </option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >Strata Company<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="K_StrataCompany"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.strataCompany"
                  :disabled="isReadOnly"
                >
                  <option
                    v-for="strataCompany in availableStrataCompanies"
                    :key="strataCompany.id"
                    :value="strataCompany"
                  >
                    {{ strataCompany.Name }}
                  </option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <label
              v-if="appData.strataCompany"
              class="col-2 col-form-label text-right"
              >Primary Policy Contact<span class="text-danger">*</span></label
            >
            <div class="col-4" v-if="appData.strataCompany">
              <ValidationProvider
                rules="required"
                name="K_PrimaryPolicyContact"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.policy.K_PrimaryPolicyContact"
                  :disabled="isReadOnly"
                >
                  <option
                    v-for="contact in getStrataCompanyContacts()"
                    :key="contact.id"
                    :value="contact"
                  >
                    {{ contact.Name }}
                  </option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >Policy From Date<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="K_FromDate"
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  class="form-control"
                  :locale="'en-AU'"
                  v-model="appData.transaction.K_FromDate"
                  :disabled="isReadOnly"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Policy To Date<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="K_ToDate"
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  class="form-control"
                  :locale="'en-AU'"
                  v-model="appData.transaction.K_ToDate"
                  :disabled="isReadOnly"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 4 -->
          <div
            class="form-group row mb-3"
            v-if="appData.transaction.K_Type === 'Take-Up'"
          >
            <label class="col-2 col-form-label text-right"
              >Insurer<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="K_Insurer"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.insurer"
                  :disabled="isReadOnly"
                >
                  <option
                    v-for="insurer in availableInsurers"
                    :key="insurer.id"
                    :value="insurer"
                  >
                    {{ insurer.Name }}
                  </option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            
            <label class="col-2 col-form-label text-right"
              >CIB Appointed Date<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required"
                name="K_AppointedDate"
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  class="form-control"
                  :locale="'en-AU'"
                  v-model="appData.policy.K_AppointedDate"
                  :disabled="isReadOnly"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 5 -->
          <div class="form-group row mb-3">
          <label class="col-2 col-form-label text-right">Product<span class="text-danger">*</span></label>
          <div class="col-4">
            <ValidationProvider rules="required" name="K_Product" v-slot="{ errors }">
              <b-form-select class="form-control" v-model="appData.product" :disabled="isReadOnly">
                <option v-for="product in availableProducts" :key="product.id" :value="product">
                  {{ product.Name }}
                </option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <label v-if="appData.transaction.K_Type === 'Take-Up'" class="col-2 col-form-label text-right">Policy Number<span class="text-danger">*</span></label>
          <div class="col-4">
            <ValidationProvider v-if="appData.transaction.K_Type === 'Take-Up'" rules="required|max:255" name="K_PolicyNumber" v-slot="{ errors }">
              <input class="form-control" maxlength="255" v-model="appData.policy.K_PolicyNumber" :disabled="isReadOnly" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style>
.form-control:disabled,
.form-control[readonly] {
  color: #3f4254;
  cursor: not-allowed;
  background-color: #f3f6f9;
}

.b-form-btn-label-control.form-control[aria-disabled="true"],
.b-form-btn-label-control.form-control[aria-readonly="true"] {
  background-color: #f3f6f9;
  cursor: not-allowed;
}
</style>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  name: "PolicyDetails",

  components: {},

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  mounted() {},

  methods: {
    getStrataCompanyContacts: function () {
      let contacts = [];
      if (
        this.appData &&
        this.appData.strataCompany &&
        this.refData &&
        Array.isArray(this.refData.allStrataCompanies)
      ) {
        let selectedSc = _.find(this.refData.allStrataCompanies, {
          id: this.appData.strataCompany.id,
        });
        if (selectedSc) {
          contacts = selectedSc.contacts;
        }
      }
      return contacts;
    },
  },

  computed: {
    availableStrataCompanies: function () {
      let strataCompanies = [];
      if (this.refData && Array.isArray(this.refData.allStrataCompanies)) {
        this.refData.allStrataCompanies.forEach((sc) => {
          strataCompanies.push({ id: sc.id, Name: sc.Name });
        });
      }
      return strataCompanies;
    },

    availableInsurers: function () {
      let insurers = [];
      if (this.refData && Array.isArray(this.refData.allInsurers)) {
        this.refData.allInsurers.forEach((insurer) => {
          insurers.push({
            id: insurer.id,
            Name: insurer.Name,
            K_Panel: insurer.K_Panel,
          });
        });
      }
      return insurers;
    },

    availableProducts: function () {
      let products = [];
      if (this.refData && Array.isArray(this.refData.allProducts)) {
        this.refData.allProducts.forEach((product) => {
          products.push({ id: product.id, Name: product.Name });
        });
      }
      return products;
    },
  },

  watch: {
    'appData.transaction.K_FromDate': function (newVal) {
      if (newVal && !this.appData.transaction.K_ToDate) {
        this.appData.transaction.K_ToDate = moment(newVal).add(12, 'months').format('YYYY-MM-DD');
      }
    },
  }
};
</script>
