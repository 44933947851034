<template>
  <ValidationObserver ref="insurerQuoteTable">
    <div>
      <table class="table table-bordered">
        <thead>
          <th width="5%" class="text-center">No.</th>
          <th width="30%" class="pl-8">Insurer</th>
          <th width="15%" class="text-center">Total Premium Payable</th>
          <th width="15%" class="text-center">Insurer Outcome</th>
          <th class="text-center">Actions</th>
          <th width="10%" class="text-center">Recommended</th>
          <th width="10%" class="text-center">
            {{ showFinalisationView ? "Quote Status" : "Ranking" }}
          </th>
        </thead>
        <tbody>
          <tr v-for="(quote, index) in sortedQuotes" :key="index">
            <td class="text-center align-middle">{{ index + 1 }}</td>
            <td class="pl-8 align-middle">
              {{ quote.K_Insurer.name }}
              <b-badge
                v-if="isCurrentInsurer(quote.K_Insurer)"
                variant="success"
                class="ml-2"
              >
                Current
              </b-badge>
            </td>

            <td class="text-center align-middle">
              <span
                v-if="
                  quote.K_TotalPremiumPayable && appData.statusFlags.feesUpdated
                "
              >
                {{ quote.K_TotalPremiumPayable | currency }}
              </span>
            </td>

            <td class="text-center align-middle">
                <b-badge
                  v-bind:variant="getInsurerOutcomeBadgeVariant(quote.K_InsurerOutcome)
                    "

                  class="pt-3 pb-3 d-block badge-custom-width"
                  >{{ quote.K_InsurerOutcome || "Update Pending" }}</b-badge
                >
            </td>


            <td class="text-center align-middle">
              <div class="d-flex justify-content-center align-items-center">
                <!-- EDIT -->
                <b-button
                  v-if="!showFinalisationView"
                  variant="link"
                  @click.prevent="openUpdateQuoteDialog(quote.id)"
                  v-b-tooltip.hover
                  title="Edit"
                  class="edit-icon-button"
                  :disabled="isReadOnly"
                >
                  <i class="fas fa-pencil-alt"></i>
                </b-button>

              <!-- INCLUDE / EXCLUDE -->
                <b-button
                  variant="link"
                  v-if="!quote.K_QuoteExcluded && !showFinalisationView && (quote.K_InsurerOutcome === 'Quote Received' || quote.K_InsurerOutcome === 'Non-Binding Quote')"
                  v-b-tooltip.hover
                  title="Included"
                  @click.prevent="toggleIncludeExclude(quote.id, false)"
                  class="visible-icon-button"
                  :disabled="isReadOnly"
                >
                  <i class="fas fa-eye"></i>
                </b-button>

                <b-button
                  variant="link"
                  v-if="quote.K_QuoteExcluded || (!quote.K_QuoteExcluded && quote.K_InsurerOutcome !== 'Quote Received' && quote.K_InsurerOutcome !== 'Non-Binding Quote') && !showFinalisationView"
                  v-b-tooltip.hover
                  title="Excluded"
                  :disabled="isReadOnly || quote.K_InsurerOutcome !== 'Quote Received' && quote.K_InsurerOutcome !== 'Non-Binding Quote'"
                  @click.prevent="toggleIncludeExclude(quote.id, true)"
                  class="hidden-icon-button"
                >
                  <i class="fas fa-eye-slash"></i>
                </b-button>

              <!-- DELETE -->
                <b-button
                  variant="link"
                  @click.prevent="deleteItem(quote)"
                  v-b-tooltip.hover
                  title="Delete"
                  class="trash-icon-button"
                  :disabled="isReadOnly"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </div>

              <!-- ACCEPT -->
              <b-button
                v-if="
                  showFinalisationView &&
                  !isReadOnly &&
                  quote.K_InsurerOutcome === 'Quote Received'
                "
                @click.prevent="openAcceptQuoteDialog(quote.id)"
              >
                Accept
              </b-button>
            </td>

            <td class="text-center align-middle">
              <span v-if="showFinalisationView">
                <span v-if="quote.K_RecommendedQuote">
                  <b-badge class="mt-1" variant="primary">Yes</b-badge>
                </span>
              </span>
              <span v-else>
                <input
                  v-if="
                    quote.K_InsurerOutcome === 'Quote Received' && !quote.saving
                  "
                  type="radio"
                  :value="true"
                  name="K_RecommendedQuote"
                  v-model="quote.K_RecommendedQuote"
                  @input="setRecommendedQuote(quote)"
                  :disabled="isReadOnly"
                />
                <b-spinner
                  v-if="quote.saving"
                  variant="primary"
                  label="Please wait..."
                ></b-spinner>
              </span>
            </td>

            <td class="text-center align-middle">
              <span v-if="!showFinalisationView" class="d-inline-block w-100">
                <div class="position-relative">
                  <div class="input-group input-group-sm">
                    <b-form-select
                      v-if="(quote.K_InsurerOutcome === 'Quote Received') && !quote.saving"
                      class="form-control text-center"
                      v-model="quote.K_QuoteRanking"
                      :disabled="isReadOnly || quote.K_RecommendedQuote"
                      @input="handleRankingChange(quote)"
                      @quoteRankingSaved="onQuoteRankingSaved"
                    >
                      <b-form-select-option :value="null">-</b-form-select-option>
                      <b-form-select-option
                        v-for="(rank, i) in validQuoteRanks"
                        :key="i"
                        :value="rank + 1"
                        :disabled="rankAlreadySelected(rank + 1, quote.id)"
                      >
                        {{ rank + 1 }}
                      </b-form-select-option>
                    </b-form-select>
                    <div v-if="quote.K_InsurerOutcome" class="input-group-append">
                      <span
                        v-if="quote.K_InsurerOutcome === 'Quote Received' && !quote.saving"
                        class="input-group-text p-0 bg-transparent border-0"
                        v-b-popover.hover.left.html="`Rankings must be;<br><br><ul style='margin-left: 20px; padding-left: 0;'><li>assigned in <u>ascending order</u></li><li>un-assigned in <u>descending order</u>.</li>`"
                      >
                        <i class="fa-solid fa-circle-info small-icon"></i>
                      </span>
                    </div>
                  </div>
                  <b-spinner
                    v-if="quote.saving"
                    variant="primary"
                    small
                    label="Please wait..."
                    class="position-absolute"
                    style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
                  ></b-spinner>
                </div>
              </span>
              <span
                v-if="showFinalisationView && quote.K_QuoteStatus !== 'Created'"
              >
                <b-badge
                  class="mt-1"
                  :variant="getQuoteStatusBadgeVariant(quote.K_QuoteStatus)"
                >
                  {{ quote.K_QuoteStatus }}
                </b-badge>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <b-modal
        ref="updateQuoteDialog"
        size="xl"
        ok-title="Save"
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        :title="
          quoteBeingUpdated ? `Quote - ${quoteBeingUpdated.K_Insurer.name}` : ''
        "
        @ok="saveQuoteDetails"
      >
        <update-quote-dialog
          v-if="quoteBeingUpdated"
          :quote="quoteBeingUpdated"
          :show-fees-commissions="appData.statusFlags.quotesUpdated"
          :is-read-only="isReadOnly"
        />
        <div v-if="quoteError" class="text-danger mt-5">{{ quoteError }}</div>
      </b-modal>

      <b-modal
        ref="acceptQuoteDialog"
        size="xl"
        ok-title="Accept"
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        :title="
          quoteBeingAccepted
            ? `Quote - ${quoteBeingAccepted.K_Insurer.name}`
            : ''
        "
        @ok="acceptQuote"
      >
        <update-quote-dialog
          v-if="quoteBeingAccepted"
          :quote="quoteBeingAccepted"
          :show-fees-commissions="true"
          :is-read-only="true"
        />
      </b-modal>

      <b-modal
    ref="deleteQuoteDialog"
    size="md"
    ok-title="Delete"
    ok-variant="danger"
    scrollable
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    :title="quoteToDelete
      ? `Quote - ${quoteToDelete.K_Insurer.name} | ${quoteToDelete.K_InsurerOutcome}`
      : ''"
    @ok="deleteQuote"
  >
    <div class="py-3">
      Are you sure you wish to delete this quote?
      <br><br>
      <strong>This action cannot be undone.</strong>
    </div>
  </b-modal>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
import UpdateQuoteDialog from "../common/UpdateQuoteDialog";

export default {
  components: { UpdateQuoteDialog },
  name: "InsurerQuoteTable",

  props: {
    appData: Object,
    isReadOnly: Boolean,
    showFinalisationView: Boolean,
  },

  data() {
    return {
      quoteError: null,
      quoteBeingUpdated: null,
      quoteBeingAccepted: null,
      quoteToDelete: null,
    };
  },

  mounted() {
    this.reorderQuotes();
  },

  computed: {
    validQuoteRanks: function () {
      let quoteRanks = [];
      if (this.appData && Array.isArray(this.appData.quotes)) {
        let validQuotes = this.appData.quotes.filter((quote) => {
          return quote.K_InsurerOutcome === "Quote Received";
        });

        for (let i = 0; i < validQuotes.length; i++) {
          // quoteRanks.push(i + 1);
          quoteRanks.push(i);
        }
      }
      return quoteRanks;
    },

    sortedQuotes() {
      if (this.appData && Array.isArray(this.appData.quotes)) {
        return _.orderBy(this.appData.quotes, ['K_QuoteRanking', 'K_InsurerOutcome'], ['asc', 'desc']);
      }
      return [];
    },
  },

  methods: {
    reorderQuotes() {
      // Ensure appData and appData.quotes are valid
      if (this.appData && Array.isArray(this.appData.quotes)) {
        // Sort quotes
        this.appData.quotes = _.orderBy(this.appData.quotes, ['K_QuoteRanking'], ['asc']);
      }
    },

    deleteItem(quote) {
      if (this.isReadOnly) return;
      this.quoteToDelete = quote;
      this.$refs.deleteQuoteDialog.show();
    },

    deleteQuote() {
      if (this.quoteToDelete) {
        this.appData.quotes = this.appData.quotes.filter(q => q.id !== this.quoteToDelete.id);

        this.$emit('deleteQuote', this.quoteToDelete.id);

        // Reset the quoteToDelete variable
        this.quoteToDelete = null;
      }
      this.$refs.deleteQuoteDialog.hide();
    },


    toggleIncludeExclude(quoteId, isIncluded) {
      if (this.isReadOnly) return;
      const index = this.appData.quotes.findIndex(q => q.id === quoteId);
      if (index !== -1) {
        this.$set(this.appData.quotes[index], 'K_QuoteExcluded', !isIncluded);
        this.$emit("saveQuote", this.appData.quotes[index]);
      }
    },

    handleRankingChange(quote) {
      // Set saving to true at the beginning of the method
      this.$set(quote, 'saving', true);
      // Emit the necessary data to the parent component
      this.$emit('rankingChanged', { quoteId: quote.id, ranking: quote.K_QuoteRanking || null });
      // Reorder the quotes array immediately
      this.reorderQuotes();
    },

    rankAlreadySelected: function (rank, currentQuoteId) {
      // Allow null/empty ranking
      if (rank === null || rank === '') {
        return false;
      }

      if (this.appData && Array.isArray(this.appData.quotes)) {
        return _.some(this.appData.quotes, (quote) => {
          return quote.id !== currentQuoteId && quote.K_QuoteRanking === rank;
        });
      }
      return false;
    },

    openUpdateQuoteDialog: function (quoteId) {
      this.quoteError = null;

      this.$commonDataService
        .getQuoteDetails(quoteId)
        .then(({ data }) => {
          this.quoteBeingUpdated = data.result;
          this.$refs["updateQuoteDialog"].show();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    openAcceptQuoteDialog: function (quoteId) {
      this.quoteError = null;
      this.$commonDataService
        .getQuoteDetails(quoteId)
        .then(({ data }) => {
          this.quoteBeingAccepted = data.result;

          // Show 'Update Quote' modal
          this.$refs["acceptQuoteDialog"].show();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    saveQuoteDetails: function (bvModalEvt) {
      if (this.isReadOnly) {
        return;
      }

      bvModalEvt.preventDefault();
      this.quoteError = null;

      this.$refs.insurerQuoteTable.validate().then((isValid) => {
        if (isValid) {
          // Quote is valid - close the dialog and save the quote
          this.$emit("saveQuote", this.quoteBeingUpdated);
          this.$refs["updateQuoteDialog"].hide();
        }
      });
    },

    setRecommendedQuote: function (quote) {
      if (this.isReadOnly) {
        return;
      }
      this.$emit("setRecommendedQuote", quote);
    },

    acceptQuote: function (/* bvModalEvt */) {
      if (this.isReadOnly) {
        return;
      }
      this.$emit("acceptQuote", this.quoteBeingAccepted);
    },

    isCurrentInsurer: function (insurer) {
      let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
      return policyInsurerId === insurer.id;
    },

    getInsurerOutcomeBadgeVariant: function (insurerOutcome) {
      if (insurerOutcome) {
        let outcome = insurerOutcome.toLowerCase();
        if (outcome.includes("quote received")) {
          return "info";
        }
        else if (outcome.includes("binding")) {
          return "success"
        }
        else {
          return "warning";
        }
      }
      return "danger";
    },

    getQuoteStatusBadgeVariant: function (quoteStatus) {
      if (quoteStatus) {
        let outcome = quoteStatus.toLowerCase();
        if (outcome === "accepted by client") {
          return "success";
        } else {
          return "secondary";
        }
      }
      return "secondary";
    },

    onQuoteRankingSaved({ quoteId }) {
      const quote = this.appData.quotes.find(q => q.id === quoteId);
      if (quote) {
        this.$set(quote, 'saving', false);
      }
    },
  },
};
</script>

<style scoped>
.edit-icon-button i {
  color: #007bff;
  opacity: 0.8;
}

.edit-icon-button:hover i {
  color: #007bff;
  opacity: 1;
}

.trash-icon-button i {
  color: #dc3545;
  opacity: 0.8;
}

.trash-icon-button:hover i {
  color: #dc3545;
  opacity: 1;
}

.visible-icon-button i {
  color: #07a651;
  opacity: 0.8;
}

.visible-icon-button:hover i {
  color: #07a651;
  opacity: 1;
}

.hidden-icon-button i {
  color: #a38b8e;
}

.badge-custom-width {
  width: 100%;
  margin: auto;
}

.input-group-sm .form-control,
.input-group-sm .input-group-text {
  font-size: 0.875rem;
  line-height: 1.5;
}

.small-icon {
  font-size: 0.875rem;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.input-group {
  flex-wrap: nowrap;
  width: 100%;
}

.input-group-append {
  flex-shrink: 0;
}

.input-group .form-control {
  min-width: 0;
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

/* Override Bootstrap's default styles for the select element */
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
</style>
