<template>
  <ValidationObserver ref="wizardPage5" id="wizardPage5" name="wizardPage5">
    <div>
      <div v-if="appData">
        <policy-alert :app-data="appData" />

        <policy-summary :app-data="appData" />

        <instructions :instructions-text="instructions" />

        <h4 class="font-weight-bold text-dark mt-10">
          <span class="ml-2" style="vertical-align: center">
            Insurer Quotes
          </span>
        </h4>
        <insurer-quote-table
          class="mt-5"
          :app-data="appData"
          :is-read-only="isReadOnly"
          @saveQuote="saveQuote"
          v-on="$listeners"
          @rankingChanged="updateQuoteRanking"
          @quoteRankingSaved="onQuoteRankingSaved"
          @deleteQuote="handleQuoteDeletion"
        >
        </insurer-quote-table>

        <!-- Hidden input to validate if all quotes have been updated -->
        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="quotesUpdated"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.statusFlags.quotesUpdated" />
          <span class="text-danger">{{
            errors[0]
              ? "Please update all quotes based on insurer's response."
              : ""
          }}</span>
        </ValidationProvider>

        <!-- BROKER FEE -->
        <b-card
          v-if="appData.statusFlags.quotesUpdated"
          body-class="px-4 py-4"
          class="mt-10"
          border-variant="secondary"
        >
          <h4 class="font-weight-bold text-dark">
            <i class="fas fa-dollar-sign fa-md text-primary"></i>
            <span class="ml-2" style="vertical-align: center">
              Broker Fee
            </span>
          </h4>

          <div class="card-text">
            Enter Broker Fee below, and click 'Apply to All Quotes'.<br />
            To modify the fees and commissions for a particular quote, click the
            'Update' button in the table above.
          </div>

          <div class="form-group row mt-5">
            <label class="col-2 col-form-label text-right"
              >Broker Fee (ex. GST)</label
            >
            <div class="col-2">
              <ValidationProvider
                rules="required"
                name="brokerFee"
                v-slot="{ errors }"
              >
                <currency-input
                  class="form-control"
                  v-model="brokerFee"
                  @input="calculateBrokerFeeGst"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Broker Fee GST</label
            >
            <div class="col-2">
              <ValidationProvider
                rules="required"
                name="brokerFeeGst"
                v-slot="{ errors }"
              >
                <currency-input
                  class="form-control"
                  v-model="brokerFeeGst"
                  disabled
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row justify-content-center mt-10">
            <button
              class="btn btn-primary"
              @click.prevent="$emit('applyBrokerFeeToAllQuotes')"
              :disabled="isReadOnly"
            >
              <i class="fa-solid fa-list-check fa-md"></i>
              Apply to All Quotes
            </button>
          </div>
          <div class="row justify-content-center">
            <!-- Hidden input to validate if fees and commissions have been applied to all quoted -->
            <ValidationProvider
              :rules="{ 'required-action': { allowFalse: false } }"
              name="feesUpdated"
              v-slot="{ errors }"
            >
              <input type="hidden" v-model="appData.statusFlags.feesUpdated" />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </b-card>

        <!-- QUOTE COMPARISON TABLE -->
        <b-card
          v-if="appData.statusFlags.feesUpdated"
          body-class="px-4 py-4"
          class="mt-10"
          border-variant="primary"
        >
          <h4 class="font-weight-bold text-dark" v-b-toggle="'ComparisonTable'">
            <i class="fas fa-list fa-md text-primary"></i>
            <span class="ml-2" style="vertical-align: center">
              Quote Comparison Table
            </span>

            <i
              v-if="!isComparisonTableExpanded"
              class="fas fa-angle-double-down fa-md float-right"
              title="Click to Expand"
            ></i>
            <i
              v-if="isComparisonTableExpanded"
              class="fas fa-angle-double-up fa-md float-right"
              title="Click to Collapse"
            ></i>
          </h4>
          <b-collapse id="ComparisonTable" v-model="isComparisonTableExpanded">
            <div class="card-text">
              Comparison of the valid quotes is shown below. Any difference in
              the values between all quotes is highlighted for your review.
            </div>
            <div class="row justify-content-center mt-5">
              <button
                class="btn btn-primary"
                @click.prevent="$emit('createQuoteComparisonTable')"
                :disabled="isReadOnly"
              >
                <i :class="iconClass + ' fa-md mr-2'"></i>
                {{
                  isComparisonDataValid
                    ? "Refresh Quote Comparison"
                    : "Show Quote Comparison"
                }}
              </button>
            </div>
            <div class="row justify-content-center mt-2">
              <!-- Hidden input to validate if comparison data has been viewed -->
              <ValidationProvider
                :rules="{ 'required-action': { allowFalse: false } }"
                name="isComparisonDataValid"
                v-slot="{ errors }"
              >
                <input type="hidden" v-model="isComparisonDataValid" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <ValidationObserver ref="comparisonTable">
              <comparison-table
                v-if="isComparisonDataValid"
                class="mt-10"
                :comparison-data="appData.quoteComparisonData"
                :is-read-only="
                  isReadOnly || appData.statusFlags.quoteComparisonConfirmed
                "
              >
              </comparison-table>
            </ValidationObserver>
          </b-collapse>
        </b-card>

        <div v-if="appData.statusFlags.feesUpdated && isComparisonDataValid">
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.statusFlags.quoteComparisonConfirmed"
            v-slot="{ errors }"
          >
            <div class="row mt-10">
              <span
                class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
              >
                <label
                  v-show="!localStatusFlags.quoteComparisonConfirmed.saving"
                >
                  <input
                    type="checkbox"
                    v-model="localStatusFlags.quoteComparisonConfirmed.value"
                    @change="flagUpdated('quoteComparisonConfirmed')"
                    :disabled="isReadOnly"
                  />
                  <span></span>
                </label>
                <b-spinner
                  variant="primary"
                  v-if="localStatusFlags.quoteComparisonConfirmed.saving"
                ></b-spinner>
              </span>
              <label class="col-form-label font-size-h5 ml-2">
                I confirm that above quote comparison data is correct.
              </label>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!-- Word document Broker Advice -->
        <b-card
          v-if="appData?.statusFlags?.quoteComparisonConfirmed"
          body-class="px-4 py-4"
          class="mt-10"
          border-variant="primary"
        >
          <h4 class="font-weight-bold text-dark mb-6">
            <i class="fas fa-file-word fa-md text-primary"></i>
            <span class="ml-2" style="vertical-align: center">
              Broker Advice
            </span>
          </h4>

          <div class="col-10">
            <Document
              document-name="Broker Advice"
              :document-data="getWordBrokerAdviceData"
              :get-broker-advice-email-template-func="getBrokerAdviceEmailTemplate"
              :on-create-document="createWordBrokerAdvice"
              :processing-indicator="creatingWordBrokerAdvice"
              :is-read-only="isReadOnly"
              :is-created="isWordBrokerAdviceCreated"
              :is-show-title="false"
              :recreate-enabled="true"
            />
          </div>

          <div v-if="wordBrokerAdviceExists" class="mt-5">
            <!-- Email Broker Advice Action -->
            <button
              v-if="
                !isReadOnly &&
                brokerAdviceFinalised &&
                !appData?.statusFlags?.wordBrokerAdviceSent
              "
              class="btn btn-primary ml-5"
              @click.prevent="getWordBrokerAdviceEmailTemplate"
              :disabled="appData.statusFlags.wordBrokerAdviceSent"
            >
              <i class="fa fa-envelope fa-md"></i>
              Email Broker Advice
            </button>

            <!-- Hidden input to validate if the broker advice has been finalised -->
            <ValidationProvider
              v-if="!isReadOnly && !wordBrokerAdviceFinalised"
              rules="is:Finalised"
              name="wordBrokerAdviceFinalised"
              v-slot="{ errors }"
            >
              <input
                type="hidden"
                v-model="appData.transaction.K_WordBrokerAdviceStatus"
              />
              <span class="text-danger" v-if="errors && errors[0]"
                >Broker advice must be finalised and sent.</span
              >
            </ValidationProvider>

            <div v-if="wordBrokerAdviceExists">
              <ValidationProvider
                :rules="{ 'required-action': { allowFalse: false } }"
                name="appData.statusFlags.brokerAdviceSent"
                v-slot="{ errors }"
              >
                <div class="row mt-5">
                  <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                    <label>
                      <input
                        type="checkbox"
                        v-model="appData.statusFlags.brokerAdviceSent"
                        :disabled="isReadOnly"
                        @change="$emit('updateServerTransactionStatus', 'brokerAdviceSent')"
                      />
                      <span></span>
                    </label>
                  </span>
                  <label class="col-form-label font-size-h5 ml-2">
                    I confirm that the Broker Advice has been sent.
                  </label>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </b-card>

        <b-modal
          ref="requestReviewDialog"
          size="md"
          ok-title="Request"
          scrollable
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          title="Request Review of Broker Advice"
          @ok="requestBrokerAdviceReview"
          @cancel="resetBrokerAdviceReviewRequest"
        >
          <ValidationObserver ref="requestReviewDialogData">
            <request-review
              :review-data="brokerAdviceReviewRequest"
              :reviewers="managerUsers"
            />
          </ValidationObserver>
        </b-modal>

        <b-modal
          ref="approveBrokerAdviceDialog"
          size="md"
          ok-title="Approve"
          scrollable
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          title="Approve Broker Advice"
          @ok="approveBrokerAdvice"
          @cancel="resetBrokerAdviceManagerResponseData"
        >
          <div class="form-group row">
            <label class="col-4 col-form-label">Approval Comments</label>
            <div class="col-8">
              <ValidationObserver ref="approvalDialogData">
                <ValidationProvider
                  rules="required|max:1000"
                  name="approvalComments"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    class="form-control min-rows"
                    v-model="brokerAdviceManagerResponseData.comments"
                  ></b-form-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </ValidationObserver>
            </div>
          </div>
        </b-modal>

        <b-modal
          ref="rejectBrokerAdviceDialog"
          size="md"
          ok-title="Save"
          scrollable
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          title="Reject Broker Advice"
          @ok="rejectBrokerAdvice"
          @cancel="resetBrokerAdviceManagerResponseData"
        >
          <div class="form-group row">
            <label class="col-4 col-form-label">Comments</label>
            <div class="col-8">
              <ValidationObserver ref="rejectionDialogData">
                <ValidationProvider
                  rules="required|max:1000"
                  name="rejectionComments"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    class="form-control min-rows"
                    v-model="brokerAdviceManagerResponseData.comments"
                  ></b-form-textarea>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </ValidationObserver>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </ValidationObserver>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
import _ from "lodash";
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import ComparisonTable from "./ComparisonTable.vue";
import InsurerQuoteTable from "../../../common/InsurerQuoteTable.vue";
import RequestReview from "./RequestReview";
import Document from "../../../common/Document.vue";

export default {
  name: "QuotesPage",
  components: {
    PolicyAlert,
    Instructions,
    PolicySummary,
    ComparisonTable,
    InsurerQuoteTable,
    RequestReview,
    Document,
  },

  props: {
    appData: Object,
    isCurrentUserManager: Boolean,
    isCurrentUserAlsoReviewer: Boolean,
    managerUsers: Array,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isComparisonTableExpanded: true,
      localStatusFlags: {
        quoteComparisonConfirmed: {
          value: this.appData.statusFlags.quoteComparisonConfirmed,
          saving: false,
        },
      },
      creatingBrokerAdvice: false,
      creatingWordBrokerAdvice: false,
      refreshingBrokerAdviceStatus: false,
      openingBrokerAdvice: false,
      openingWordBrokerAdvice: false,

      brokerAdviceReviewRequest: {
        reviewer: null,
        comments: null,
      },
      brokerAdviceManagerResponseData: {
        status: null,
        comments: null,
      },
    };
  },

  computed: {
    instructions: function () {
      return "For each of the selected insurers, please update the details of the insurer's quotation and also specify the overall outcome for that insurer (e.g. Quoted, Declined, etc).";
    },

    iconClass() {
      return this.isComparisonDataValid
        ? "fa-solid fa-sync"
        : "fa-solid fa-eye";
    },

    isComparisonDataValid: function () {
      return (
        this.appData &&
        this.appData.quoteComparisonData &&
        Array.isArray(this.appData.quoteComparisonData.insurers)
      );
    },

    isWordBrokerAdviceCreated() {
      return (
        this.appData?.transaction?.K_BrokerAdviceStatus === "Created" ||
        this.appData?.transaction?.K_BrokerAdviceStatus === "Finalised" ||
        !!(
          this.getWordBrokerAdviceData.id &&
          this.getWordBrokerAdviceData.view_url
        )
      );
    },

    getWordBrokerAdviceIdName() {
      return this.appData?.documents?.wordBrokerAdvice
        ? "appData.documents.wordBrokerAdvice.id"
        : "wordBrokerAdviceId";
    },

    getWordBrokerAdviceId() {
      return this.appData?.documents?.wordBrokerAdvice?.id ?? "";
    },

    brokerAdviceTemplates: function () {
      // return ["GENERAL", "NSW", "QLD", "VIC", "ACT"]; // awaiting templates from CIB
      return ["GENERAL"];
    },

    wordBrokerAdviceTemplates: function () {
      // return ["GENERAL", "NSW", "QLD", "VIC", "ACT"]; // awaiting templates from CIB
      return ["CIB"];
    },

    wordBrokerAdviceExists: function () {
      return !!this.appData?.documents?.brokerAdvice?.id;
    },

    getWordBrokerAdviceData() {
      const data = {
        id: this.appData?.documents?.brokerAdvice?.id || this.appData?.transaction?.K_BrokerAdviceLink,
        view_url: this.appData?.transaction?.K_BrokerAdviceLink || this.appData?.documents?.brokerAdvice?.view_url,
      };
      return data;
    },

    wordBrokerAdviceData() {
      return this.appData?.documents?.wordBrokerAdvice ?? {};
    },

    wordBrokerAdviceFinalised: function () {
      let wordBrokerAdviceStatus =
        this.appData?.transaction?.K_WordBrokerAdviceStatus;
      return wordBrokerAdviceStatus === "Finalised";
    },

    wordBrokerAdviceStatusBadgeVariant: function () {
      let wordBrokerAdviceStatus =
        this.appData?.transaction?.K_BrokerAdviceStatus;
      return wordBrokerAdviceStatus === "Finalised" ? "success" : "warning";
    },

    wordBrokerAdviceButtonPrefix: function () {
      let wordBrokerAdviceStatus =
        this.appData?.transaction?.K_BrokerAdviceStatus;

      if (wordBrokerAdviceStatus === "Draft") {
        return "Edit";
      } else if (wordBrokerAdviceStatus === "Awaiting Review") {
        return this.isCurrentUserManager ? "Review" : "View";
      }
      return "View";
    },

    brokerAdviceExists: function () {
      let brokerAdviceId = _.get(this.appData, "documents.brokerAdvice.id");
      return !!brokerAdviceId;
    },

    brokerAdviceFinalised: function () {
      let brokerAdviceStatus = _.get(
        this.appData,
        "transaction.K_BrokerAdviceStatus"
      );
      return brokerAdviceStatus === "Finalised";
    },

    brokerAdviceStatusBadgeVariant: function () {
      let brokerAdviceStatus = _.get(
        this.appData,
        "transaction.K_BrokerAdviceStatus"
      );
      if (brokerAdviceStatus === "Finalised") {
        return "success";
      } else {
        return "warning";
      }
    },

    brokerAdviceInstructions: function () {
      let brokerAdviceStatus = _.get(
        this.appData,
        "transaction.K_BrokerAdviceStatus"
      );
      let instructions = null;
      switch (brokerAdviceStatus) {
        case "Draft":
          instructions = `Click the 'Edit Broker Advice' button to update the document as required. Then save the Broker Advice.`;
          break;
        case "Ready For Finalisation":
        case "Approved":
          instructions = `Click the 'View Broker Advice' button to open the Broker Advice. Then click the 'Finalise' button on the Broker Advice.<br/>
            Optionally, you can click on the 'Request Manager Review' button to request a review of the Broker Advice.`;
          break;
        case "Awaiting Review": {
          if (this.isCurrentUserManager) {
            instructions = `Click the 'Review Broker Advice' button to open the Broker Advice. You can edit the document as required.
            Be sure to save the document after making changes. Then Approve or Reject using the buttons below.`;
          }
          break;
        }
        case "Finalised":
          instructions = `Click the 'Email Broker Advice' button and then confirm that you have sent the Broker Advice to the client.`;
          break;
      }
      return instructions;
    },

    brokerAdviceIconClass: function () {
      let prefix = this.brokerAdviceButtonPrefix;
      if (prefix === "Edit") {
        return "far fa-edit fa-md";
      } else if (prefix === "Review") {
        return "fa-regular fa-clipboard fa-md mr-2";
      } else {
        return "far fa-eye fa-md";
      }
    },

    brokerAdviceButtonPrefix: function () {
      let brokerAdviceStatus = _.get(
        this.appData,
        "transaction.K_BrokerAdviceStatus"
      );

      let prefix = "View";
      if (brokerAdviceStatus === "Draft") {
        prefix = "Edit";
      } else if (brokerAdviceStatus === "Awaiting Review") {
        prefix = this.isCurrentUserManager ? "Review" : "View";
      }
      return prefix;
    },

    brokerFee: {
      get() {
        // If null or empty, return 0
        return this.appData.transaction
          ? this.appData.transaction.K_BrokerFee || 0
          : 0;
      },
      set(value) {
        if (this.appData.transaction) {
          this.appData.transaction.K_BrokerFee = value;
        }
      },
    },
    brokerFeeGst: {
      get() {
        // If null or empty, return 0
        return this.appData.transaction
          ? this.appData.transaction.K_BrokerFeeGst || 0
          : 0;
      },
      set(value) {
        if (this.appData.transaction) {
          this.appData.transaction.K_BrokerFeeGst = value;
        }
      },
    },
  },

  methods: {
    saveQuote: function (quote) {
      this.$emit("saveQuote", quote);

      // Reset quote comparison data and flags
      this.localStatusFlags.quoteComparisonConfirmed.value = false;
      this.appData.quoteComparisonData = null;
    },

    handleQuoteDeletion(quoteId) {
      const index = this.appData.quotes.findIndex((q) => q.id === quoteId);
      if (index !== -1) {
        this.appData.quotes.splice(index, 1);
      }
      this.$emit("deleteQuoteRequest", quoteId);
    },

    updateQuoteRanking({ quoteId, ranking }) {
      const quote = this.appData.quotes.find((q) => q.id === quoteId);
      if (quote) {
        this.$set(quote, "K_QuoteRanking", ranking);
        this.$emit("quoteRankingUpdated", quote);
        this.$emit("quoteRankingSaved", { quoteId });
      }
    },

    getBrokerAdviceEmailTemplate: function () {
      this.$commonDataService
        .getEmailTemplate("SEND_BROKER_ADVICE")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    createWordBrokerAdvice: function () {
      let template =
        this.appData?.transaction?.K_BrokerAdviceWordTemplate || "CIB";

      this.creatingWordBrokerAdvice = true;
      this.$renewalDataService
        .createWordBrokerAdvice(template)
        .then(({ data }) => {
          if (data.result) {
            let wordBrokerAdviceInfo = data.result;

            // Ensure the documents object exists
            if (!this.appData.documents) {
              this.$set(this.appData, "documents", {});
            }

            // Set the wordBrokerAdvice data
            this.$set(this.appData.documents, "wordBrokerAdvice", {
              id: wordBrokerAdviceInfo.id,
              view_url: wordBrokerAdviceInfo.K_RenewalLetterLink,
            });

            // Set the K_WordBrokerAdviceLink in the transaction
            this.$set(
              this.appData.transaction,
              "K_WordBrokerAdviceLink",
              wordBrokerAdviceInfo.K_RenewalLetterLink
            );
            this.$set(
              this.appData.transaction,
              "K_BrokerAdviceLink",
              wordBrokerAdviceInfo.K_RenewalLetterLink
            );
            this.$set(
              this.appData.transaction,
              "K_WordBrokerAdviceStatus",
              "Created"
            );

            // Force a re-render of the Document component
            this.$nextTick(() => {
              this.$forceUpdate();
            });

            // Emit an event to notify the parent to save the data
            this.$emit("saveAppData");
          }
          this.creatingWordBrokerAdvice = false;
        })
        .catch((error) => {
          this.creatingWordBrokerAdvice = false;
          this.$emit("handleError", error);
        });
    },
    calculateBrokerFeeGst: function () {
      this.$set(
        this.appData.transaction,
        "K_BrokerFeeGst",
        this.calculateGst(this.appData.transaction.K_BrokerFee)
      );
    },

    calculateGst: function (currencyValue) {
      if (!_.isNil(currencyValue)) {
        return this.round(Number(currencyValue) * 0.1);
      }
      return null;
    },

    round: function (number) {
      if (!_.isNil(number)) {
        return Math.round(Number(number) * 100 + Number.EPSILON) / 100;
      }
      return null;
    },

    flagUpdated: async function (flagName) {
      let flagData = this.localStatusFlags[flagName];

      if (flagData) {
        // If quoteComparisonConfirmed was set to true, validate and save the historical comparison data
        if (
          flagName === "quoteComparisonConfirmed" &&
          flagData.value === true
        ) {
          let isComparisonTableValid =
            await this.$refs.comparisonTable.validate();
          if (!isComparisonTableValid) {
            flagData.value = !flagData.value;
            return;
          } else {
            // Save quote comparison data
            flagData.saving = true;
            let errorFlag = false;
            try {
              await this.$renewalDataService.saveQuoteComparisonData(
                this.appData.quoteComparisonData
              );
            } catch (error) {
              errorFlag = true;
              this.$emit("handleError", error);
            }

            if (errorFlag) {
              flagData.value = !flagData.value;
              flagData.saving = false;
              return;
            }
          }
        }

        flagData.saving = true;
        this.$renewalDataService
          .updateTransactionStatus(flagName, flagData.value)
          .then(({ data }) => {
            let updatedStatus = data.result;
            if (updatedStatus) {
              // Update appData flags
              this.appData.statusFlags[flagName] = flagData.value;

              // Update transaction status
              let txn = this.appData.transaction;
              txn.K_Status = updatedStatus.K_Status;
              txn.K_StatusDetails = updatedStatus.K_StatusDetails;
              txn.K_NextStep = updatedStatus.K_NextStep;
              txn.K_DueInWeek = updatedStatus.K_DueInWeek;
              txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;

              // Fire event to update the global transaction status on the UI
              this.$emit("statusUpdated");

              this.$emit("updateWizardAppDataString");
            }

            flagData.saving = false;
          })
          .catch((error) => {
            // Reset to original value
            flagData.value = !flagData.value;
            flagData.saving = false;
            this.$emit("handleError", error);
          });
      }
    },

    createBrokerAdvice: function () {
      let template = this.appData.transaction.K_BrokerAdviceTemplate;

      this.creatingBrokerAdvice = true;
      this.$renewalDataService
        .createBrokerAdvice(template)
        .then(({ data }) => {
          if (data.result) {
            let brokerAdviceInfo = data.result;
            this.appData.documents.brokerAdvice = {
              id: brokerAdviceInfo.id,
              view_url: brokerAdviceInfo.K_BrokerAdviceLink,
            };
            this.appData.transaction.K_BrokerAdviceStatus =
              brokerAdviceInfo.K_BrokerAdviceStatus;

            this.$emit("updateWizardAppDataString");
          }
          this.creatingBrokerAdvice = false;
        })
        .catch((error) => {
          this.creatingBrokerAdvice = false;
          this.$emit("handleError", error);
        });
    },

    getBrokerAdviceLinkAndOpen: function () {
      this.openingBrokerAdvice = true;
      this.$renewalDataService
        .getBrokerAdviceLink()
        .then(({ data }) => {
          let link = data.result;
          this.openingBrokerAdvice = false;
          window.open(link, "_blank");
        })
        .catch((error) => {
          this.openingBrokerAdvice = false;
          this.$emit("handleError", error);
        });
    },

    getWordBrokerAdviceLinkAndOpen: function () {
      this.openingWordBrokerAdvice = true;
      this.$renewalDataService
        .getWordBrokerAdviceLink()
        .then(({ data }) => {
          let link = data.result;
          this.openingWordBrokerAdvice = false;
          window.open(link, "_blank");
        })
        .catch((error) => {
          this.openingWordBrokerAdvice = false;
          this.$emit("handleError", error);
        });
    },

    refreshBrokerAdviceStatus: function () {
      if (this.isReadOnly) {
        return;
      }

      this.refreshingBrokerAdviceStatus = true;
      this.$renewalDataService
        .refreshBrokerAdviceStatus()
        .then(({ data }) => {
          if (data.result) {
            let baStatusInfo = data.result;

            this.appData.transaction.K_BrokerAdviceStatus =
              baStatusInfo.K_BrokerAdviceStatus;
            this.appData.transaction.K_BrokerAdviceReviewComments =
              baStatusInfo.K_BrokerAdviceReviewComments;
            this.appData.transaction.K_BrokerAdviceReviewer =
              baStatusInfo.K_BrokerAdviceReviewer;
            this.$emit("updateWizardAppDataString");
          }
          this.refreshingBrokerAdviceStatus = false;
        })
        .catch((error) => {
          this.refreshingBrokerAdviceStatus = false;
          this.$emit("handleError", error);
        });
    },

    openRequestReviewDialog: function () {
      this.$refs["requestReviewDialog"].show();
    },

    requestBrokerAdviceReview: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.requestReviewDialogData.validate().then((isValid) => {
        if (isValid) {
          // Save the request to the server
          this.$emit(
            "requestBrokerAdviceReview",
            _.cloneDeep(this.brokerAdviceReviewRequest)
          );

          // Reset local data
          this.resetBrokerAdviceReviewRequest();

          // Hide the dialog
          this.$refs["requestReviewDialog"].hide();
        }
      });
    },

    resetBrokerAdviceReviewRequest: function () {
      this.brokerAdviceReviewRequest = {
        reviewer: null,
        comments: null,
      };
    },

    openApproveBrokerAdviceDialog: function () {
      this.$refs["approveBrokerAdviceDialog"].show();
    },

    approveBrokerAdvice: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.approvalDialogData.validate().then((isValid) => {
        if (isValid) {
          // Hide the dialog
          this.$refs["approveBrokerAdviceDialog"].hide();

          this.brokerAdviceManagerResponseData.status = "approved";
          // Save the request to the server
          this.$emit(
            "approveBrokerAdvice",
            _.cloneDeep(this.brokerAdviceManagerResponseData)
          );

          // Reset local data
          this.resetBrokerAdviceManagerResponseData();
        }
      });
    },

    resetBrokerAdviceManagerResponseData: function () {
      this.brokerAdviceManagerResponseData = {
        status: null,
        comments: null,
      };
    },

    openRejectBrokerAdviceDialog: function () {
      this.$refs["rejectBrokerAdviceDialog"].show();
    },

    rejectBrokerAdvice: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.rejectionDialogData.validate().then((isValid) => {
        if (isValid) {
          // Hide the dialog
          this.$refs["rejectBrokerAdviceDialog"].hide();

          this.brokerAdviceManagerResponseData.status = "rejected";
          // Save the request to the server
          this.$emit(
            "rejectBrokerAdvice",
            _.cloneDeep(this.brokerAdviceManagerResponseData)
          );

          // Reset local data
          this.brokerAdviceManagerResponseData = {
            status: null,
            comments: null,
          };
        }
      });
    },

    onQuoteRankingSaved({ quoteId }) {
      this.$emit("quoteRankingSaved", { quoteId });
    },
  },

  watch: {
    appData: {
      handler(newVal) {
        if (newVal && newVal.transaction && newVal.transaction.K_BrokerAdviceStatus === 'Created') {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      },
      deep: true
    },
  }
};
</script>
