<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div>
      <div v-if="appData">
        <instructions :instructions-text="instructions" />

        <div class="mt-5 mb-5">
          <h4>This policy is a..</h4>
          <ValidationProvider
            rules="required"
            name="TransactionType"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="appData.transaction.K_Type"
              @change="transactionTypeChanged"
            >
              <v-radio
                label="A brand new policy"
                value="New Business"
                class="switch-radio"
                :disabled="isReadOnly"
              ></v-radio>
              <v-radio
                label="A take-up of an existing policy"
                value="Take-Up"
                class="switch-radio"
                :disabled="isReadOnly"
              ></v-radio>
            </v-radio-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <policy-details
          :app-data="appData"
          :ref-data="refData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <building-details
          :app-data="appData"
          :ref-data="refData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <div class="mt-10">
          <h4>How do you wish to enter/obtain the building details?</h4>

          <ValidationProvider
            rules="required"
            name="declarationRequired"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="appData.declarationRequired"
              @change="setPolicyEnteredStatus"
            >
              <v-radio
                label="I want to send a Declaration Form to the Strata Manager"
                value="yes"
                class="switch-radio"
                :disabled="isReadOnly"
              ></v-radio>
              <v-radio
                label="I will enter these manually"
                value="no"
                class="switch-radio"
                :disabled="isReadOnly"
              ></v-radio>
            </v-radio-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import Instructions from "../../../common/Instructions.vue";
import PolicyDetails from "./PolicyDetails.vue";
import BuildingDetails from "./BuildingDetails.vue";

export default {
  name: "NewPolicyPage",

  components: {
    Instructions,
    PolicyDetails,
    BuildingDetails,
  },
  data() {
    return {};
  },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  methods: {
    transactionTypeChanged: function () {
      if (this.appData.transaction.K_Type === "Take-Up") {
        this.appData.insurer = null;
        this.appData.policy.K_PolicyNumber = null;
      }
      this.$emit("updateWizardTitle");
    },

    setPolicyEnteredStatus: function () {
      this.appData.statusFlags.policyDetailsEntered = true;
      this.$emit("updateServerTransactionStatus", "policyDetailsEntered");
    },
  },

  computed: {
    instructions: function () {
      return "Please select the policy type (Take Up or New Business) then enter the general details relating to the policy and building below.";
    },
  },
};
</script>
