<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'building-details-panel'"
      >
        <i class="fas fa-list-alt fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center"
          >Building Details</span
        >

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse id="building-details-panel" v-model="isExpanded">
        <div v-if="appData && refData">
          <!-- ROW 1 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >Building Name<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="BuildingName"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="255"
                  v-model="appData.asset.Name"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >Strata Plan Number<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="K_PolicyNumber"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="255"
                  v-model="appData.asset.K_PlanNumber"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Building Type<span class="text-danger">*</span></label
            >
            <div class="col-2">
              <ValidationProvider
                rules="required"
                name="K_BuildingType"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.asset.K_BuildingType"
                  :disabled="isReadOnly"
                >
                  <option :value="null">-- Select --</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Mixed Use">Mixed Use</option>
                  <option value="Industrial">Industrial</option>
                  <option value="Neighbourhood Association">
                    Neighbourhood Association
                  </option>
                  <option value="Community Association">
                    Community Association
                  </option>
                  <option value="Company Title">Company Title</option>
                  <option value="Tenants in Common">Tenants in Common</option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >Address<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="K_Address"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="255"
                  v-model="appData.asset.K_Address"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Suburb<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="K_Suburb"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="255"
                  v-model="appData.asset.K_Suburb"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 4 -->
          <div class="form-group row mb-3">
            <label class="col-2 col-form-label text-right"
              >State<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:255"
                name="K_State"
                v-slot="{ errors }"
              >
                <b-form-select
                  class="form-control"
                  v-model="appData.asset.K_State"
                  :disabled="isReadOnly"
                >
                  <option :value="null">-- Select --</option>
                  <option value="NSW">NSW</option>
                  <option value="ACT">ACT</option>
                  <option value="QLD">QLD</option>
                  <option value="VIC">VIC</option>
                  <option value="SA">SA</option>
                  <option value="WA">WA</option>
                  <option value="NT">NT</option>
                  <option value="TAS">TAS</option>
                </b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <label class="col-2 col-form-label text-right"
              >Postcode<span class="text-danger">*</span></label
            >
            <div class="col-4">
              <ValidationProvider
                rules="required|max:4"
                name="K_Postcode"
                v-slot="{ errors }"
              >
                <input
                  class="form-control"
                  maxlength="4"
                  v-model="appData.asset.K_Postcode"
                  :disabled="isReadOnly"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "BuildingDetails",

  components: {},

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  mounted() {},

  methods: {},

  computed: {},
};
</script>
