<template>
  <ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
    <div>
      <div v-if="appData">
        <policy-alert :app-data="appData" />

        <policy-summary :app-data="appData" />

        <instructions :instructions-text="instructions" />

        <div>
          <div class="row mt-10">
            <div class="form-group col-2">
              <label class="col-form-label font-weight-bold"
                >Renewal From Date</label
              >
            </div>
            <div class="form-group col-2">
              <ValidationProvider
                rules="required"
                name="appData.transaction.K_FromDate"
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  class="form-control"
                  :locale="'en-AU'"
                  v-model="appData.transaction.K_FromDate"
                  disabled
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                >
                </b-form-datepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group col-2">
              <label class="col-form-label font-weight-bold float-right"
                >Renewal To Date</label
              >
            </div>
            <div class="form-group col-2">
              <ValidationProvider
                rules="required"
                name="appData.transaction.K_ToDate"
                v-slot="{ errors }"
              >
                <b-form-datepicker
                  class="form-control"
                  :locale="'en-AU'"
                  v-model="appData.transaction.K_ToDate"
                  :disabled="isReadOnly || quoteSlipExists"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                >
                </b-form-datepicker>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <ValidationProvider
            rules="max:2000"
            name="appData.transaction.K_SpecialInstructionsForInsurer"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label class="col-form-label font-weight-bold font-size-lg">
                Special Instructions for Insurers (to be included in the Quote
                Slip)
              </label>
              <b-textarea
                class="form-control min-rows"
                v-model="appData.transaction.K_SpecialInstructionsForInsurer"
                :disabled="isReadOnly || quoteSlipExists"
                trim
                maxlength="2000"
              ></b-textarea>
              <small class="form-text text-muted">Maximum 2,000 characters</small>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <div class="form-group" v-if="!quoteSlipExists">
            <button
              class="btn btn-primary"
              :disabled="isReadOnly || creatingQuoteSlip || isOverLimit"
              @click.prevent="createQuoteSlip"
            >
              Create Quote Slip
            </button>
            <b-spinner
              v-if="creatingQuoteSlip"
              class="ml-3 align-middle"
              variant="primary"
              label="Please wait..."
            ></b-spinner>
          </div>
          <div class="form-group" v-if="quoteSlipExists">
            <button
              class="btn btn-sm btn-outline-danger"
              :disabled="isReadOnly"
              @click.prevent="openQuoteSlipResetConfirmation"
            >
              Unlock Above Fields
            </button>
          </div>

          <Document
            v-if="quoteSlipExists"
            class="mt-10"
            :document-name="'Quote Slip'"
            :document-data="appData.documents.quoteSlip"
            :on-create-document="createQuoteSlip"
            :processing-indicator="creatingQuoteSlip"
            :download-enabled="false"
            :is-read-only="isReadOnly"
          >
          </Document>

          <!-- Hidden input to validate if the user created the quote slip -->
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.documents.quoteSlip.id"
            v-slot="{ errors }"
          >
            <input type="hidden" v-model="appData.documents.quoteSlip.id" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--        style="max-height: 100px !important; overflow-y: scroll;"-->

        <div v-if="quoteSlipExists">
          <b-card border-variant="primary" body-class="px-4 py-4" class="mt-15">
            <h4 class="font-weight-bold text-dark">
              <span class="ml-2" style="vertical-align: center"
                >Select Insurers</span
              >
            </h4>

            <div class="row mt-6">
              <div class="col-6 pr-4">
                <h5 class="ml-2 mt-3"><b-badge>Panel A</b-badge></h5>

                  <table
                    class="table table-borderless"

                  >
                    <tr v-for="item in panelAInsurers" :key="item.id">
                      <td style="width: 23px; vertical-align: middle;">
                        <b-form-checkbox
                          size="lg"
                          v-model="item.selected"
                          :disabled="isReadOnly ||
                            isCurrentInsurer(item) ||
                            insurerSelectionConfirmed
                            "
                        >
                        </b-form-checkbox>
                      </td>
                      <td style="vertical-align: middle;">
                      <span>
                      {{ item.Name }}
                      <b-badge
                        v-if="isCurrentInsurer(item)"
                        variant="success"
                        class="ml-2"
                      >
                        Current
                      </b-badge>
                      </span>

                      </td>
                      <!-- <td width="50%">
                        <b-form-select
                          v-model="item.selectedContact"
                          :disabled="isReadOnly ||
                            !item.selected ||
                            insurerSelectionConfirmed
                            "
                        >
                          <b-form-select-option :value="null"
                            >Select a Contact</b-form-select-option
                          >
                          <b-form-select-option
                            v-for="contact in item.contacts"
                            :key="contact.id"
                            :value="contact"
                            >{{ contact.Name }} - {{ contact.Email }}
                          </b-form-select-option>
                        </b-form-select>
                      </td> -->
                      <td width="40%">
                        <v-autocomplete
                          v-model="item.selectedContact"
                          :items="item.contacts"
                          item-text="Name"
                          item-value="id"
                          :disabled="isReadOnly || !item.selected || insurerSelectionConfirmed"
                          dense
                          chips
                          small-chips
                          clearable
                          :label="item.selectedContact ? '' : 'Select'"
                          hint="Start typing to search contacts"
                          return-object
                          persistent-hint
                          hide-details
                          class="mt-0 pt-0 mb-2 custom-autocomplete"
                          :menu-props="{ offsetY: true }"
                        >
                          <template v-slot:selection="{ item }">
                          <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    small
                                    class="custom-chip mb-1"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ item.Name }}
                                  </v-chip>
                                </template>
                                <span>
                                  {{ item.Email }}<br>
                                  {{ item.K_Phone }}<br>
                                  {{ item.K_Mobile }}
                                </span>
                              </v-tooltip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{ item.Name }}</v-list-item-title>
                              <v-list-item-subtitle><i class="fa-solid fa-envelope fa-sm mr-1"></i>{{ item.Email }}</v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.K_Phone"><i class="fa-solid fa-phone fa-sm mr-1"></i> {{ item.K_Phone }}</v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.K_Mobile"><i class="fa-solid fa-mobile fa-sm mr-1"></i> {{ item.K_Mobile }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                  </table>
              </div>

              <div class="col-6">
                <h5 class="ml-2 mt-3"><b-badge>Panel B</b-badge></h5>
                <div class="grid-container">
                  <table
                    class="table table-borderless"
                    style="display: block; height: 1400px; overflow-y: auto"
                  >
                    <tr v-for="item in panelBInsurers" :key="item.id">
                      <td style="width: 23px; vertical-align: middle;">
                        <b-form-checkbox
                          size="lg"
                          v-model="item.selected"
                          :disabled="isReadOnly ||
                            isCurrentInsurer(item) ||
                            insurerSelectionConfirmed
                            "
                        >
                        </b-form-checkbox>
                      </td>
                      <td style="vertical-align: middle">
                        {{ item.Name }}
                        <b-badge
                          v-if="isCurrentInsurer(item)"
                          variant="light"
                          class="ml-1"
                        >
                          Current
                        </b-badge>
                      </td>
                      <td width="40%">
                        <v-autocomplete
                          v-model="item.selectedContact"
                          :items="item.contacts"
                          item-text="Name"
                          item-value="id"
                          :disabled="isReadOnly || !item.selected || insurerSelectionConfirmed"
                          dense
                          chips
                          small-chips
                          clearable
                          :label="item.selectedContact ? '' : 'Select'"
                          hint="Start typing to search contacts"
                          return-object
                          persistent-hint
                          hide-details
                          class="mt-0 pt-0 mb-2 custom-autocomplete"
                          :menu-props="{ offsetY: true }"
                        >
                          <template v-slot:selection="{ item }">
                          <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    small
                                    class="custom-chip mb-1"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ item.Name }}
                                  </v-chip>
                                </template>
                                <span>
                                  {{ item.Email }}<br>
                                  {{ item.K_Phone }}<br>
                                  {{ item.K_Mobile }}
                                </span>
                              </v-tooltip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{ item.Name }}</v-list-item-title>
                              <v-list-item-subtitle><i class="fa-solid fa-envelope fa-sm mr-1"></i>{{ item.Email }}</v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.K_Phone"><i class="fa-solid fa-phone fa-sm mr-1"></i> {{ item.K_Phone }}</v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.K_Mobile"><i class="fa-solid fa-mobile fa-sm mr-1"></i> {{ item.K_Mobile }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>
          </b-card>

          <div class="text-danger mt-5" v-if="insurerSelectionError">
            {{ insurerSelectionError }}
          </div>
          <div class="form-group mt-5" v-if="!insurerSelectionConfirmed">
            <button
              class="btn btn-primary"
              @click.prevent="finaliseInsurers"
              :disabled="savingData"
            >
              Confirm Insurers Selection
            </button>
            <b-spinner
              v-if="savingData"
              class="ml-3 align-middle"
              variant="primary"
              label="Please wait..."
            ></b-spinner>
          </div>
          <div class="form-group mt-5" v-if="insurerSelectionConfirmed">
            <button
              class="btn btn-sm btn-outline-danger"
              :disabled="isReadOnly"
              @click.prevent="openInsurerSelectionResetConfirmation"
            >
              Unlock Insurer Selection
            </button>
          </div>

          <!-- Hidden input to validate if the insurer selection is confirmed -->
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="insurerSelectionConfirmed"
            v-slot="{ errors }"
          >
            <input type="hidden" v-model="insurerSelectionConfirmed" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>

          <div v-if="insurerSelectionConfirmed" class="mt-10">
            <div class="form-group row">
              <button
                class="btn btn-primary ml-4"
                v-if="currentInsurerSelected"
                @click.prevent="handleHoldingInsurerEmailRequest"
                :disabled="isReadOnly || appData.statusFlags.quoteSlipSent"
              >
              <i class="fa-solid fa-envelope"></i>
                Email Renewal Quote Slip
              </button>

              <button
                class="btn btn-primary ml-5"
                v-if="otherInsurerSelected"
                @click.prevent="handleOtherInsurerEmailRequest"
                :disabled="isReadOnly || appData.statusFlags.quoteSlipSent"
              >
              <i class="fa-solid fa-envelope"></i>
                Email New Business Quote Slip
              </button>

            </div>
            <!-- <Document
                :document-name="'Renewal Quote Slip'"
                :document-data="appData.documents.quoteSlip"
                :get-holding-insurer-quote-slip-email="getHoldingInsurerQuoteSlipEmailTemplate"
                :on-create-document="createQuoteSlip"
                :processing-indicator="creatingQuoteSlip"
                :is-read-only="isReadOnly"
                :is-submitted="appData.statusFlags.insurerSelectionConfirmed"
            >
            </Document> -->

            <ValidationProvider
              :rules="{ 'required-action': { allowFalse: false } }"
              name="appData.statusFlags.quoteSlipSent"
              v-slot="{ errors }"
            >
              <div class="row mt-10">
                <span
                  class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
                >
                  <label>
                    <input
                      type="checkbox"
                      :disabled="isReadOnly"
                      v-model="appData.statusFlags.quoteSlipSent"
                      @change="
                        $emit('updateServerTransactionStatus', 'quoteSlipSent')
                      "
                    />
                    <span></span>
                  </label>
                </span>
                <label class="col-form-label font-size-h5 ml-2">
                  I confirm that I have sent the Quote Slip to the selected
                  insurers.
                </label>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <b-modal
        ref="quoteSlipResetConfirmationDialog"
        title="Confirm"
        size="md"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="resetQuoteSlip"
      >
        This action will invalidate the previously generated Quote Slip, and you
        will need to generate a new Quote Slip and send it to the insurers.<br /><br />
        Are you sure you want to continue?
      </b-modal>

      <b-modal
        ref="insurerSelectionResetConfirmationDialog"
        title="Confirm"
        size="md"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        @ok="resetInsurerSelection"
      >
        This action will reset insurer selection, and you may need to send the
        Quote Slip to the newly selected insurers.<br /><br />
        Are you sure you want to continue?
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px; /* Space between grid items */
}
.custom-autocomplete {
  ::v-deep {
    .v-label {
      font-size: 14px;
    }

    .v-select__selection {
      font-size: 14px;
    }
  }

  .custom-chip {
      background-color: #2583d9;
      color: white;
  }
}

</style>

<script>
import _ from "lodash";
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import Document from "../../../common/Document";

export default {
  name: "InsurersPage",

  components: { PolicyAlert, Instructions, PolicySummary, Document },

  props: {
    appData: Object,
    isReadOnly: Boolean,
    getInsurerQuoteSlipEmailTemplate: {
      type: Function,
      required: false
    },
    getHoldingInsurerQuoteSlipEmailTemplate: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      panelAInsurers: null,
      panelBInsurers: null,
      selectedInsurers: null,
      insurerSelectionError: null,
      insurerSelectionConfirmed: false,
      creatingQuoteSlip: false,
      savingData: false,
    };
  },
  mounted() {
    this.insurerSelectionConfirmed = this.appData.statusFlags.quoteSlipSent;

    let panelAInsurers = _.filter(this.appData.availableInsurers, {
      K_Panel: "Panel A",
    });
    if (!Array.isArray(panelAInsurers)) {
      panelAInsurers = [];
    }
    let panelBInsurers = _.filter(this.appData.availableInsurers, {
      K_Panel: "Panel B",
    });
    if (!Array.isArray(panelBInsurers)) {
      panelBInsurers = [];
    }

    this.panelAInsurers = _.cloneDeep(panelAInsurers);
    this.panelBInsurers = _.cloneDeep(panelBInsurers);

    this.panelAInsurers.forEach((ins) => {
      this.$set(ins, "selected", false);
      this.$set(ins, "selectedContact", null);
    });
    this.panelBInsurers.forEach((ins) => {
      this.$set(ins, "selected", false);
      this.$set(ins, "selectedContact", null);
    });

    if (this.appData && Array.isArray(this.appData.selectedInsurers)) {
      this.appData.selectedInsurers.forEach((selectedInsurer) => {
        // Find selectedInsurer in panels
        let insInPanel = _.find(this.panelAInsurers, {
          id: selectedInsurer.id,
        });
        if (!insInPanel || !insInPanel.id) {
          insInPanel = _.find(this.panelBInsurers, { id: selectedInsurer.id });
        }

        if (insInPanel && insInPanel.id) {
          insInPanel.selected = true;
          insInPanel.selectedContact = selectedInsurer.selectedContact;
        }
      });
    }
  },

  methods: {
    openQuoteSlipResetConfirmation: function () {
      this.$refs["quoteSlipResetConfirmationDialog"].show();
    },

    handleHoldingInsurerEmailRequest() {
      this.$emit('requestHoldingInsurerEmailTemplate');
    },

    handleOtherInsurerEmailRequest() {
      this.$emit('requestOtherInsurerEmailTemplate');
    },

    resetQuoteSlip: function () {
      this.appData.transaction.K_QuoteSlipLink = null;
      this.appData.documents.quoteSlip = {};
      this.appData.statusFlags.quoteSlipSent = false;
      this.insurerSelectionConfirmed = false;
    },

    createQuoteSlip: function () {
      let renewalToDate = this.appData.transaction.K_ToDate;
      let insurerInstructions =
        this.appData.transaction.K_SpecialInstructionsForInsurer;

      this.creatingQuoteSlip = true;
      this.$renewalDataService
        .createQuoteSlip(renewalToDate, insurerInstructions)
        .then(({ data }) => {
          if (data.result) {
            let quoteSlipInfo = data.result;
            this.appData.documents.quoteSlip = {
              id: quoteSlipInfo.id,
              view_url: quoteSlipInfo.K_QuoteSlipLink,
            };
            this.$emit("updateWizardAppDataString");
          }
          this.creatingQuoteSlip = false;
        })
        .catch((error) => {
          this.creatingQuoteSlip = false;
          this.$emit("handleError", error);
        });
    },

    isCurrentInsurer: function (insurer) {
      let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
      return policyInsurerId === insurer.id;
    },

    finaliseInsurers: function () {
      this.insurerSelectionError = null;

      let selectedInsurers = _.filter(this.panelAInsurers, { selected: true });
      if (!Array.isArray(selectedInsurers)) {
        selectedInsurers = [];
      }
      selectedInsurers = selectedInsurers.concat(
        _.filter(this.panelBInsurers, { selected: true })
      );

      // Confirm that insurer-contact has been selected
      let invalidInsurer = _.some(selectedInsurers, (ins) => {
        return !ins.selectedContact || !ins.selectedContact.id;
      });
      if (invalidInsurer) {
        this.insurerSelectionError = `Please select the contacts for all selected insurers.`;
        return;
      }

      this.appData.selectedInsurers = [];
      selectedInsurers.forEach((selectedInsurer) => {
        this.appData.selectedInsurers.push({
          id: selectedInsurer.id,
          Name: selectedInsurer.Name,
          K_Panel: selectedInsurer.K_Panel,
          selectedContact: selectedInsurer.selectedContact,
        });
      });

      // Save selected insurers to server
      this.savingData = true;
      this.$renewalDataService
        .saveSelectedInsurers(this.appData.selectedInsurers)
        .then(({ data }) => {
          if (data.result) {
            this.appData.quotes = data.result;
            this.$emit("updateWizardAppDataString");

            this.savingData = false;
            this.insurerSelectionConfirmed = true;
          }
        })
        .catch((error) => {
          this.$emit("handleError", error);
          this.savingData = false;
        });
    },

    openInsurerSelectionResetConfirmation: function () {
      this.$refs["insurerSelectionResetConfirmationDialog"].show();
    },

    resetInsurerSelection: function () {
      this.insurerSelectionConfirmed = false;
      this.appData.statusFlags.quoteSlipSent = false;
    },

    launchMailToLink: function (link) {
      if (link) {
        window.open(link);
      }
    },
  },

  computed: {
    instructions: function () {
      return "Please specify any special instructions for the insurer to consider when providing a quote, if any. Then create the Quote Slip PDF document by clicking the button below. A copy of the Quote Slip will be stored in the Documents folder in Box. <br><br>After creating the Quote Slip, send it to the relevant Insurers.";
    },

    isOverLimit() {
      if (this.appData && this.appData.transaction && this.appData.transaction.K_SpecialInstructionsForInsurer !== null) {
        return this.appData.transaction.K_SpecialInstructionsForInsurer.length > 2000;
      }
      return false;
    },

    quoteSlipExists: function () {
      let quoteSlipLink = _.get(this.appData, "documents.quoteSlip.view_url");
      return !!quoteSlipLink;
    },

    otherInsurerSelected: function () {
      if (this.appData && Array.isArray(this.appData.selectedInsurers)) {
        let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
        return _.some(this.appData.selectedInsurers, (insurer) => {
          return (
            insurer.id !== policyInsurerId &&
            insurer.selectedContact &&
            insurer.selectedContact.id
          );
        });
      }
      return false;
    },

    currentInsurerSelected: function () {
      if (this.appData && Array.isArray(this.appData.selectedInsurers)) {
        let policyInsurerId = _.get(this.appData, "policy.K_Insurer.id");
        return _.some(this.appData.selectedInsurers, (insurer) => {
          return (
            insurer.id === policyInsurerId &&
            insurer.selectedContact &&
            insurer.selectedContact.id
          );
        });
      }
      return false;
    },
  },
};
</script>
