<template>
  <div v-if="quote">
    <div class="form-group row mt-5">
      <label class="col-2 col-form-label text-right">Commission Type</label>
      <div class="col-2">
        <ValidationProvider
          rules="required"
          name="commissionType"
          v-slot="{ errors }"
        >
          <b-form-radio-group
            class="mt-3"
            v-model="quote.K_CommissionType"
            :options="commissionTypes"
            @change="commissionTypeChanged"
            :disabled="isReadOnly"
          ></b-form-radio-group>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="form-group row" v-if="quote.K_CommissionType === 'Gross'">
      <label class="col-2 col-form-label text-right"
        >Insurer Commission (ex. GST)</label
      >
      <div class="col-2">
        <ValidationProvider
          rules="required"
          name="insurerCommission"
          v-slot="{ errors }"
        >
          <currency-input
            class="form-control"
            v-model="quote.K_InsurerCommission"
            @input="insurerCommissionChanged"
            :disabled="isReadOnly"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <label class="col-2 col-form-label text-right"
        >Insurer Commission GST</label
      >
      <div class="col-2">
        <ValidationProvider
          rules="required"
          name="insurerCommissionGst"
          v-slot="{ errors }"
        >
          <currency-input
            class="form-control"
            v-model="quote.K_InsurerCommissionGst"
            disabled
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <label class="col-2 col-form-label text-right"
        >Insurer Commission %</label
      >
      <div class="col-2">
        <ValidationProvider
          rules="required"
          name="insurerCommissionPercent"
          v-slot="{ errors }"
        >
          <b-input-group append="%">
            <input
              type="number"
              class="form-control"
              v-model="quote.K_InsurerCommissionPercent"
              disabled
            />
          </b-input-group>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div v-if="showCommissions">
      <div class="form-group row mt-5">
        <label class="col-2 col-form-label text-right"
          >Broker Fee (ex. GST)</label
        >
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="brokerFee"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="brokerFee"
              @input="brokerFeeChanged"
              :disabled="isReadOnly"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <label class="col-2 col-form-label text-right">Broker Fee GST</label>
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="brokerFeeGst"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="brokerFeeGst"
              disabled
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label text-right">AR Income %</label>
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="arIncomePercent"
            v-slot="{ errors }"
          >
            <b-input-group append="%">
              <input
                type="number"
                class="form-control"
                v-model="arIncomePercent"
                @input="arIncomePercentChanged"
                min="0"
                max="100"
                :disabled="isReadOnly"
              />
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <label class="col-2 col-form-label text-right"
          >AR Income (ex. GST)</label
        >
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="arIncome"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="arIncomeExGst"
              @input="arIncomeChanged"
              :disabled="isReadOnly"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <label class="col-2 col-form-label text-right">AR Income GST</label>
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="arIncomeGst"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="arIncomeGst"
              disabled
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label text-right">CIB Income %</label>
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="cibIncomePercent"
            v-slot="{ errors }"
          >
            <b-input-group append="%">
              <input
                type="number"
                class="form-control"
                v-model="cibIncomePercent"
                min="0"
                max="100"
                @input="cibIncomePercentChanged"
                :disabled="isReadOnly"
              />
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <label class="col-2 col-form-label text-right"
          >CIB Income (ex. GST)</label
        >
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="cibIncome"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="cibIncomeExGst"
              @input="cibIncomeChanged"
              :disabled="isReadOnly"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <label class="col-2 col-form-label text-right">CIB Income GST</label>
        <div class="col-2">
          <ValidationProvider
            rules="required"
            name="cibIncomeGst"
            v-slot="{ errors }"
          >
            <currency-input
              class="form-control"
              v-model="cibIncomeGst"
              disabled
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
//@ts-ignore
import _ from "lodash";

export default {
  name: "Instructions",
  props: {
    quote: Object,
    isReadOnly: Boolean,
    showCommissions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    commissionTypes: function () {
      return [
        { text: "Net", value: "Net" },
        { text: "Gross", value: "Gross" },
      ];
    },
    brokerFeeGst: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_BrokerFeeGst || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_BrokerFeeGst = value; 
        }
      }
    },
    brokerFee: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_BrokerFee || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_BrokerFee = value;
        }
      }
    },
    arIncomePercent: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_ArIncomePercent || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_ArIncomePercent = value;
        }
      }
    },
    cibIncomePercent: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_CibIncomePercent || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_CibIncomePercent = value;
        }
      }
    },
    arIncomeExGst: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_ArIncome || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_ArIncome = value;
        }
      }
    },
    arIncomeGst: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_ArIncomeGst || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_ArIncomeGst = value;
        }
      }
    },
    cibIncomeExGst: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_CibIncome || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_CibIncome = value;
        }
      }
    },
    cibIncomeGst: {
      get() {
        // If null or empty, return 0
        return this.quote && (this.quote.K_CibIncomeGst || 0);
      },
      set(value) {
        if (this.quote) {
          this.quote.K_CibIncomeGst = value;
        }
      }
    },
  },

  methods: {
    commissionTypeChanged: function () {
      if (this.quote.K_CommissionType === "Net") {
        this.$set(this.quote, "K_InsurerCommission", null);
        this.$set(this.quote, "K_InsurerCommissionGst", null);
        this.$set(this.quote, "K_InsurerCommissionPercent", null);
      }
      this.calculateTotalCibIncome();
    },

    insurerCommissionChanged: function () {
      this.$set(
        this.quote,
        "K_InsurerCommissionGst",
        this.calculateGst(this.quote.K_InsurerCommission)
      );
      this.$set(
        this.quote,
        "K_InsurerCommissionPercent",
        this.calculatePercent(
          this.quote.K_InsurerCommission,
          this.quote.K_BasePremium
        )
      );

      if (this.quote.K_CommissionType === "Gross") {
        this.$set(
          this.quote,
          "K_ArIncome",
          this.calculatePercentAmount(
            this.quote.K_InsurerCommission,
            this.quote.K_ArIncomePercent
          )
        );
        this.$set(
          this.quote,
          "K_ArIncomeGst",
          this.calculateGst(this.quote.K_ArIncome)
        );
        this.$set(
          this.quote,
          "K_CibIncome",
          this.calculatePercentAmount(
            this.quote.K_InsurerCommission,
            this.quote.K_CibIncomePercent
          )
        );
        this.$set(
          this.quote,
          "K_CibIncomeGst",
          this.calculateGst(this.quote.K_CibIncome)
        );

        this.calculateTotalCibIncome();
      }
    },

    brokerFeeChanged: function () {
      this.$set(
        this.quote,
        "K_BrokerFeeGst",
        this.calculateGst(this.quote.K_BrokerFee)
      );

      if (this.quote.K_CommissionType === "Net") {
        this.$set(
          this.quote,
          "K_ArIncome",
          this.calculatePercentAmount(
            this.quote.K_BrokerFee,
            this.quote.K_ArIncomePercent
          )
        );
        this.$set(
          this.quote,
          "K_ArIncomeGst",
          this.calculateGst(this.quote.K_ArIncome)
        );
        this.$set(
          this.quote,
          "K_CibIncome",
          this.calculatePercentAmount(
            this.quote.K_BrokerFee,
            this.quote.K_CibIncomePercent
          )
        );
        this.$set(
          this.quote,
          "K_CibIncomeGst",
          this.calculateGst(this.quote.K_CibIncome)
        );
      }
      this.calculateTotalCibIncome();
      this.$emit("brokerFeeChanged");
    },

    arIncomePercentChanged: function () {
      this.$set(
        this.quote,
        "K_ArIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_ArIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_ArIncomeGst",
        this.calculateGst(this.quote.K_ArIncome)
      );

      let cibIncomePercent = null;
      if (!_.isNil(this.quote.K_ArIncomePercent)) {
        cibIncomePercent = this.round(
          100 - Number(this.quote.K_ArIncomePercent)
        );
      }
      this.$set(this.quote, "K_CibIncomePercent", cibIncomePercent);
      this.$set(
        this.quote,
        "K_CibIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_CibIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_CibIncomeGst",
        this.calculateGst(this.quote.K_CibIncome)
      );

      this.calculateTotalCibIncome();
    },

    arIncomeChanged: function () {
      this.$set(
        this.quote,
        "K_ArIncomeGst",
        this.calculateGst(this.quote.K_ArIncome)
      );
      this.$set(
        this.quote,
        "K_ArIncomePercent",
        this.calculatePercent(this.quote.K_ArIncome, this.quote.K_BrokerFee)
      );

      let cibIncomePercent = null;
      if (!_.isNil(this.quote.K_ArIncomePercent)) {
        cibIncomePercent = this.round(
          100 - Number(this.quote.K_ArIncomePercent)
        );
      }
      this.$set(this.quote, "K_CibIncomePercent", cibIncomePercent);
      this.$set(
        this.quote,
        "K_CibIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_CibIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_CibIncomeGst",
        this.calculateGst(this.quote.K_CibIncome)
      );

      this.calculateTotalCibIncome();
    },

    cibIncomePercentChanged: function () {
      this.$set(
        this.quote,
        "K_CibIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_CibIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_CibIncomeGst",
        this.calculateGst(this.quote.K_CibIncome)
      );

      let arIncomePercent = null;
      if (!_.isNil(this.quote.K_CibIncomePercent)) {
        arIncomePercent = this.round(
          100 - Number(this.quote.K_CibIncomePercent)
        );
      }
      this.$set(this.quote, "K_ArIncomePercent", arIncomePercent);
      this.$set(
        this.quote,
        "K_ArIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_ArIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_ArIncomeGst",
        this.calculateGst(this.quote.K_ArIncome)
      );

      this.calculateTotalCibIncome();
    },

    cibIncomeChanged: function () {
      this.$set(
        this.quote,
        "K_CibIncomeGst",
        this.calculateGst(this.quote.K_CibIncome)
      );
      this.$set(
        this.quote,
        "K_CibIncomePercent",
        this.calculatePercent(this.quote.K_CibIncome, this.quote.K_BrokerFee)
      );

      let arIncomePercent = null;
      if (!_.isNil(this.quote.K_CibIncomePercent)) {
        arIncomePercent = this.round(
          100 - Number(this.quote.K_CibIncomePercent)
        );
      }
      this.$set(this.quote, "K_ArIncomePercent", arIncomePercent);
      this.$set(
        this.quote,
        "K_ArIncome",
        this.calculatePercentAmount(
          this.quote.K_BrokerFee,
          this.quote.K_ArIncomePercent
        )
      );
      this.$set(
        this.quote,
        "K_ArIncomeGst",
        this.calculateGst(this.quote.K_ArIncome)
      );

      this.calculateTotalCibIncome();
    },

    calculateTotalCibIncome: function () {
      let totalCibIncome = null;
      let totalCibIncomeGst = null;
      if (this.quote.K_CommissionType === "Net") {
        // Commission-Type Net --> Total CIB Income = CIB Income
        totalCibIncome = this.quote.K_CibIncome;
        totalCibIncomeGst = this.quote.K_CibIncomeGst;
      } else {
        // Commission-Type Gross --> Total CIB Income = CIB Income + Broker Fee
        totalCibIncome = this.calculateSum(
          this.quote.K_CibIncome,
          this.quote.K_BrokerFee
        );
        totalCibIncomeGst = this.calculateSum(
          this.quote.K_CibIncomeGst,
          this.quote.K_BrokerFeeGst
        );
      }

      this.$set(this.quote, "K_TotalCibIncome", totalCibIncome);
      this.$set(this.quote, "K_TotalCibIncomeGst", totalCibIncomeGst);
    },

    calculateGst: function (currencyValue) {
      if (!_.isNil(currencyValue)) {
        return this.round(Number(currencyValue) * 0.1);
      }
      return null;
    },

    calculatePercent: function (value, parentValue) {
      if (!_.isNil(value) && !_.isNil(parentValue)) {
        return this.round((Number(value) * 100) / Number(parentValue));
      }
      return null;
    },

    calculatePercentAmount: function (currencyValue, percent) {
      if (!_.isNil(currencyValue) && !_.isNil(percent)) {
        return this.round((Number(currencyValue) * Number(percent)) / 100);
      }
      return null;
    },

    calculateSum: function (value1, value2) {
      if (!_.isNil(value1) && !_.isNil(value2)) {
        return this.round(Number(value1) + Number(value2));
      }
      return null;
    },

    round: function (number) {
      if (!_.isNil(number)) {
        return Math.round(Number(number) * 100 + Number.EPSILON) / 100;
      }
      return null;
    },
  },

};
</script>
